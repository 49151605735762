/**
 * Main JS
 *
 */

import Swal from 'sweetalert2';

window.Main = new (function () {

    const that = this;

    let onScroll = function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 30)
                $('header').addClass('stick')
            else
                $('header').removeClass('stick')
        })
    }

    let menu = function () {
        $('body').toggleClass('noScroll');

        if ($('body').hasClass('noScroll')) {
            setTimeout(function () {
                $('.dg-mainMenu .close span').addClass('active');
            }, 500);
        } else {
            $('.dg-mainMenu .close span').removeClass('active');
        }
    };

    this.init = function () {

        $('.collapse').collapse();

        $('.menuMb .hamburger').on('click', menu);

        $('.acc-mainMenu .text-up').on('click', that.textUp);
        $('.acc-mainMenu .text-down').on('click', that.textDown);

        //play sound
        $('.audio').on('click', that.playSound);
        //play video
        $('.lsm').on('click', that.playVideo);

        //
        $('.dg-mainMenu .close').on('click', function () {
            $('.menuMb .hamburger').trigger('click');
        });

        //Widget grandes
        $('.switch').on('click', that.manageBigWidget);
        //Manage speaker
        $('.dg-mainMenu .speaker-function').on('click', that.speaker);
        //Manage lsm
        $('.dg-mainMenu .lsm-function').on('click', that.lsm);
        //Manage no images
        $('.dg-mainMenu .no-images-function').on('click', that.manageNoImages);

        //reset settings
        $('.reset-settings').on('click', that.resetSettings);

        //go back with accord
        $('.back-to-menu').on('click', that.goBackAccordion);

        //open accordion
        $('.card-header .main-text').on('click', function () {

            if( $(this).parent().hasClass('active') ) {
                //close accordion
                $(this).parent().removeClass('active');
                $('.back-to-menu').removeClass('active');
                $('.card-header').removeClass('f-d-none');
                return;
            }

            $('.card-header').removeClass('f-d-none');
            $(this).parent().addClass('active');
            $('.back-to-menu').addClass('active');

            let restOfCardHeader = $('.card-header');

            $.each($(restOfCardHeader), function (index, value) {
                if (!$(value).hasClass('active')) {
                    $(value).addClass('f-d-none');
                }
            });

        });

        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();

            if (scroll > 30)
                $('header').addClass('stick')
            else
                $('header').removeClass('stick')
        });

        $(document).on('click', '.play-video .play-video-again', that.playVideoAgain);

        $("body").onSwipe(function(results){
            if(results.left == true) {
                $('.play-video').attr('style', 'left: -100%');
                setTimeout(function () {
                    $('.play-video').remove();
                }, 2000);
            }

        });

        $(document).on('click', '.close-video', function (){
            $('.play-video').attr('style', 'left: -100%');
            setTimeout(function () {
                $('.play-video').remove();
            }, 2000);
        });

        $('.switch-contrast').on('click', that.switchContrast);

        $('.text-function').on('click', that.textFunction);

        $('.saturation-function').on('click', that.saturationFunction);

        $('.alignment-function').on('click', that.alignmentFunction);

        $('.line-function').on('click', that.lineFunction);

        $('.space-function').on('click', that.spaceFunction);

        console.log('%c TG Soluciones© https://tgsoluciones.com ', 'background: #000; color: #FFC430; border:3px solid #3D97B3; padding: 20px;');
    };

    /**
     * Switch contrast
     */
    this.switchContrast = function () {
        var contrastValue = $(this).data('switchcontrast');

        $(this).toggleClass('on');

        if ($(this).hasClass('on')) {
            $(this).find('label').html('Contraste <span class="ok mini"></span>');
            $(this).data('switchcontrast', '0');
        } else {
            $(this).find('label').html('Contraste');
            $(this).data('switchcontrast', '1');
        }

        $.ajax({
            type: "GET",
            url: '/manage-contrast/' + contrastValue,
        }).done(function (result) {
            let status = (contrastValue == 1) ? 'on' : 'off';
            $('body').removeClass('contrast-off');
            $('body').removeClass('contrast-on');
            $('body').addClass('contrast-' + status);
        });
    };

    /**
     * Text function
     */
    this.textFunction = function () {
        var textLevel = $(this).data('switchtext');
        textLevel = parseInt(textLevel) + 1;

        if( textLevel < 4 ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
            textLevel = 0;
        }


        if ($(this).hasClass('on')) {
            $(this).find('label').html('Gran texto <span class="ok mini"></span>');
            $(this).data('switchtext', textLevel);
        } else {
            $(this).find('label').html('Gran texto ');
            $(this).data('switchtext', '0');
        }

        $.ajax({
            type: "GET",
            url: '/manage-text/' + textLevel,
        }).done(function (result) {
            let status = (textLevel < 4) ? textLevel : '0';
            $('body').removeClass('grand-text-level-0');
            $('body').removeClass('grand-text-level-1');
            $('body').removeClass('grand-text-level-2');
            $('body').removeClass('grand-text-level-3');
            $('body').removeClass('grand-text-level-4');
            $('body').addClass('grand-text-level-' + status);
        });
    };

    /**
     * Saturation function
     */
    this.saturationFunction = function () {
        var saturationLevel = $(this).data('switchsaturation');
        saturationLevel = parseInt(saturationLevel) + 1;

        if( saturationLevel < 3 ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
            saturationLevel = 0;
        }


        if ($(this).hasClass('on')) {
            $(this).find('label').html('Saturación <span class="ok mini"></span>');
            $(this).data('switchsaturation', saturationLevel);
        } else {
            $(this).find('label').html('Saturación');
            $(this).data('switchsaturation', '0');
        }

        $.ajax({
            type: "GET",
            url: '/manage-saturation/' + saturationLevel,
        }).done(function (result) {
            let status = (saturationLevel < 3) ? saturationLevel : '0';
            $('body').removeClass('saturation-level-0');
            $('body').removeClass('saturation-level-1');
            $('body').removeClass('saturation-level-2');
            $('body').addClass('saturation-level-' + status);
        });
    };

    /**
     * Alignment function
     */
    this.alignmentFunction = function () {
        var alignmentLevel = $(this).data('switchalignment');
        alignmentLevel = parseInt(alignmentLevel) + 1;

        if( alignmentLevel < 4 ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
            alignmentLevel = 0;
        }


        if ($(this).hasClass('on')) {
            $(this).find('label').html('Texto alineado <span class="ok mini"></span>');
            $(this).data('switchalignment', alignmentLevel);
        } else {
            $(this).find('label').html('Texto alineado');
            $(this).data('switchalignment', '0');
        }

        $.ajax({
            type: "GET",
            url: '/manage-alignment/' + alignmentLevel,
        }).done(function (result) {
            let status = (alignmentLevel < 4) ? alignmentLevel : '0';
            $('body').removeClass('alignment-level-0');
            $('body').removeClass('alignment-level-1');
            $('body').removeClass('alignment-level-2');
            $('body').removeClass('alignment-level-3');
            $('body').removeClass('alignment-level-4');
            $('body').addClass('alignment-level-' + status);
        });
    };

    /**
     * Line function
     */
    this.lineFunction = function () {
        var lineLevel = $(this).data('switchline');
        lineLevel = parseInt(lineLevel) + 1;

        if( lineLevel < 4 ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
            lineLevel = 0;
        }


        if ($(this).hasClass('on')) {
            $(this).find('label').html('Altura de la linea <span class="ok mini"></span>');
            $(this).data('switchline', lineLevel);
        } else {
            $(this).find('label').html('Altura de la linea');
            $(this).data('switchline', '0');
        }

        $.ajax({
            type: "GET",
            url: '/manage-line/' + lineLevel,
        }).done(function (result) {
            let status = (lineLevel < 4) ? lineLevel : '0';
            $('body').removeClass('line-level-0');
            $('body').removeClass('line-level-1');
            $('body').removeClass('line-level-2');
            $('body').removeClass('line-level-3');
            $('body').removeClass('line-level-4');
            $('body').addClass('line-level-' + status);
        });
    };

    /**
     * Space function
     */
    this.spaceFunction = function () {
        var spaceLevel = $(this).data('switchspace');
        spaceLevel = parseInt(spaceLevel) + 1;

        if( spaceLevel < 4 ) {
            $(this).addClass('on');
        } else {
            $(this).removeClass('on');
            spaceLevel = 0;
        }


        if ($(this).hasClass('on')) {
            $(this).find('label').html('Espacio de texto <span class="ok mini"></span>');
            $(this).data('switchspace', spaceLevel);
        } else {
            $(this).find('label').html('Espacio de texto');
            $(this).data('switchspace', '0');
        }

        $.ajax({
            type: "GET",
            url: '/manage-space/' + spaceLevel,
        }).done(function (result) {
            let status = (spaceLevel < 4) ? spaceLevel : '0';
            $('body').removeClass('space-level-0');
            $('body').removeClass('space-level-1');
            $('body').removeClass('space-level-2');
            $('body').removeClass('space-level-3');
            $('body').removeClass('space-level-4');
            $('body').addClass('space-level-' + status);
        });
    };


    /**
     * textUp
     */
    this.textUp = function (e) {
        e.preventDefault();
        var currentFont = parseInt($('body').css('font-size')) + 1;

        $.ajax({
            type: "GET",
            url: '/font-size/' + currentFont,
        }).done(function (result) {
            $('body').css('font-size', currentFont);
        });
    }; //textUp

    /**
     * textDown
     */
    this.textDown = function (e) {
        e.preventDefault();
        var currentFont = parseInt($('body').css('font-size')) - 1;

        $.ajax({
            type: "GET",
            url: '/font-size/' + currentFont,
        }).done(function (result) {
            $('body').css('font-size', currentFont);
        });
    }; //textDown

    /**
     * play sound
     */
    this.playSound = function () {
        $(this).find('.play-sound')[0].play();
    };

    /**
     * play video
     */
    this.playVideo = function () {

        //if video is playing, remove it
        if ($('.play-video').length > 0) {
            $('.play-video').remove();
        }
        //get the video
        let videoFile = $(this).find('.video-to-play video').data('src');
        var $video = $(this).find('.video-to-play video');
        $('source', $video).attr('src', videoFile);

        $(this).find('.video-to-play video')[0].load();
        let videoTag = $(this).find('.video-to-play video').get(0);
        let theVideo = $(this).find('.video-to-play').html();
        let secondsBeforeClose = (videoTag.duration * 1030);
        let videoHTML = '<div class="play-video"><a href="javascript:;" class="close-video"></a>' + theVideo + ' <button class="play-video-again lsm"></button><label><- Swipe para cerrar</label></div>';

        //put the video visible
        $('body').append(videoHTML);
        //$('.play-video').attr('style', 'left:' + (xyPosition.left - 200) + 'px;top:' + xyPosition.top + 'px;z-index:3');
        $('.play-video video').attr('id', 'playingVideo');
        //play the video
        $('.play-video video').get(0).play();

        //animate the teext
        $('.main-text').removeClass('animate');
        let parentForAnimation = $(this).data('parent');
        //$('.' + parentForAnimation).addClass('animate');
        //$('.' + parentForAnimation).attr('style', 'z-index:5');

        //close everything
        setTimeout(function () {
            //$('.' + parentForAnimation).removeClass('animate').attr('style: z-index:3');
            //$('.play-video').remove();
        }, secondsBeforeClose + 2000);

    };

    /**
     * Play current video again
     */
    this.playVideoAgain = function () {
        $(this).prev().get(0).play();
    };

    /**
     * manage speaker
     */
    this.speaker = function (e) {
        e.preventDefault();
        let currentValue = $(this).data('speaker');
        $(this).toggleClass('off');

        if ($(this).hasClass('off')) {
            $(this).find('.ok').remove();
            $(this).find('img').attr('src', '/images/icon-speaker-off.webp');
            $(this).data('speaker', '0');
        } else {
            $(this).find('label').html('Speaker <span class="ok mini"></span>');
            $(this).find('img').attr('src', '/images/icon-speaker.webp');
            $(this).data('speaker', '1');
        }

        $.ajax({
            type: "GET",
            url: '/speaker/' + currentValue,
        }).done(function (result) {
            let status = (currentValue == 1) ? 'on' : 'off';
            $('body').removeClass('speaker-off');
            $('body').removeClass('speaker-on');
            $('body').toggleClass('speaker-' + status);
        });
    }; //speaker

    /**
     * manage lsm
     */
    this.lsm = function (e) {
        e.preventDefault();
        let currentValue = $(this).data('lsm');
        $(this).toggleClass('off');

        if ($(this).hasClass('off')) {
            $(this).find('.ok').remove();
            $(this).find('img').attr('src', '/images/icon-lsm-off.webp');
            $(this).data('lsm', '0');
        } else {
            $(this).find('label').html('Lengua de señas mexicana <span class="ok mini"></span>');
            $(this).find('img').attr('src', '/images/icon-lsm.webp');
            $(this).data('lsm', '1');
        }

        $.ajax({
            type: "GET",
            url: '/lsm/' + currentValue,
        }).done(function (result) {
            let status = (currentValue == 1) ? 'on' : 'off';
            $('body').removeClass('lsm-off');
            $('body').removeClass('lsm-on');
            $('body').toggleClass('lsm-' + status);
        });
    }; //lsm

    /**
     * manage big widget
     */
    this.manageBigWidget = function () {
        var bigWidgetValue = 0;

        $(this).toggleClass('checked');
        $('#widgetXL').prop("checked");

        if ($(this).hasClass('checked')) {
            $('.switch').find('.slider').addClass('checked');
            bigWidgetValue = 1;
        } else {
            $('.switch').find('.slider').removeClass('checked');
        }

        $.ajax({
            type: "GET",
            url: '/manage-big-widget/' + bigWidgetValue,
        }).done(function (result) {
            let status = (bigWidgetValue == 1) ? 'on' : 'off';
            $('body').removeClass('widget-off');
            $('body').removeClass('widget-on');
            $('body').addClass('widget-' + status);
        });

    };

    /**
     * manage images
     */
    this.manageNoImages = function () {
        var noImagesValue = $(this).data('noimage');

        $(this).toggleClass('on');

        if ($(this).hasClass('on')) {
            $(this).find('label').html('Ocultar imágenes <span class="ok mini"></span>');
            $(this).data('noimage', '0');
        } else {
            $(this).find('label').html('Ocultar imágenes');
            $(this).data('noimage', '1');
        }

        $.ajax({
            type: "GET",
            url: '/manage-no-images/' + noImagesValue,
        }).done(function (result) {
            let status = (noImagesValue == 1) ? 'on' : 'off';
            $('body').removeClass('noImages-off');
            $('body').removeClass('noImages-on');
            $('body').addClass('noImages-' + status);
        });

    };

    /**
     * reset settings
     */
    this.resetSettings = function () {
        let currentBodyClass = $('body').data('currentclass');
        $(this).addClass('animate');

        $.ajax({
            type: "GET",
            url: '/reset-settings/',
        }).done(function (result) {
            $('body').removeClass();
            $('body').addClass('noScroll');
            $('body').addClass(currentBodyClass);

            //Reset big widget
            $('.switch').removeClass('checked');
            $('.switch').find('.slider').removeClass('checked');
            $('#widgetXL').prop("checked");

            //Reset lsm layout
            $('.lsm-function').find('label').html('Lengua de señas mexicana <span class="ok mini"></span>');
            $('.lsm-function').find('img').attr('src', '/images/icon-lsm.webp');
            $('.lsm-function').data('lsm', '1');

            //Reset speaker layout
            $('.speaker-function').find('label').html('Speaker <span class="ok mini"></span>');
            $('.speaker-function').find('img').attr('src', '/images/icon-speaker.webp');
            $('.speaker-function').data('speaker', '1');

            setTimeout(function () {
                $('.reset-settings').removeClass('animate');
            }, 1500);
        });

    };

    /**
     * show back button accordion
     */
    this.goBackAccordion = function () {
        //$('.back-to-menu').toggleClass('active');
        $('.card-header.active .main-text').trigger('click');
        $('.card-header').removeClass('f-d-none');
        $('.card-header').removeClass('active');
        $(this).removeClass('active');
    };

})();
